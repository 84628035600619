<template>
	<div class=" ">
		<el-card>
			<div slot="header" class="card_title">
				<span>活动</span>
			</div>
			<div class="add_main hidden_scroll">
				<span class="font_weight_bold">活动信息</span>
				<div class="margin_top_20">
					<el-form
						label-position="right"
						label-width="120px"
						:model="formData"
						:rules="rule"
						ref="formData"
					>
						<el-form-item label="标题" class="margin_top_20" prop="title">
							<el-input
								v-model="formData.title"
								placeholder=""
								:disabled="$route.query.type == 'detail'"
							></el-input>
						</el-form-item>
						<el-form-item label="封面图片" prop="pic">
							<img
								style="width: 40%"
								:src="formData.pic"
								@click="uploadPicture(formData.pic)"
							/>
						</el-form-item>
						<el-form-item label="活动开始时间" prop="start_time">
							<el-date-picker
								v-model="formData.start_time"
								value-format="yyyy-MM-dd HH:mm:ss"
								format="yyyy-MM-dd HH:mm:ss"
								type="datetime"
								placeholder=""
								:disabled="$route.query.type == 'detail'"
							>
							</el-date-picker>
						</el-form-item>

						<el-form-item label="活动结束时间" prop="end_time">
							<el-date-picker
								v-model="formData.end_time"
								value-format="yyyy-MM-dd HH:mm:ss"
								format="yyyy-MM-dd HH:mm:ss"
								type="datetime"
								placeholder=""
								:disabled="$route.query.type == 'detail'"
							>
							</el-date-picker>
						</el-form-item>
						<el-form-item label="活动地址" prop="address">
							<el-input
								v-model="formData.address"
								placeholder=""
								:disabled="$route.query.type == 'detail'"
							></el-input>
						</el-form-item>
						<el-form-item label="活动官网" prop="url">
							<el-input
								v-model="formData.url"
								placeholder=""
								:disabled="$route.query.type == 'detail'"
							>
							</el-input>
						</el-form-item>

						<el-form-item label="活动介绍" class="margin_top_20" prop="content">
							<p v-html="formData.content"></p>
							<!-- <el-input
								v-model="formData.content"
								class="custom_input_350"
								type="textarea"
								:rows="4"
								:disabled="$route.query.type == 'detail'"
							>
							</el-input> -->
						</el-form-item>

						<el-form-item label="活动类型" class="margin_top_20" prop="type">
							<el-select
								v-model="formData.type"
								placeholder=""
								:disabled="$route.query.type == 'detail'"
							>
								<el-option label="线上活动" value="5"> </el-option>
								<el-option label="线下活动" value="6"> </el-option>
							</el-select>
						</el-form-item>

						<el-form-item label="主办方" class="margin_top_20" prop="type">
							<addPeople
								style="margin-top: 10px"
								v-for="(item, index) in hostsArray"
								:key="index"
								@confirmation="addHost"
								:item="item"
							>
							</addPeople>
						</el-form-item>

						<el-form-item label="嘉宾" class="margin_top_20" prop="type">
							<addPeople
								style="margin-top: 10px"
								v-for="(item, index) in coHostsArray"
								:key="index"
								@confirmation="addHost"
								:item="item"
							>
							</addPeople>
						</el-form-item>

						<el-form-item label="活动票务" class="margin_top_20" prop="type">
							<div class="ac_create_ticket">
								<div class="ac_create_ticket_header">
									<div class="ac_create_ticket_header_1">Name ticket</div>
									<div class="ac_create_ticket_header_2">Type ticket</div>
									<div class="ac_create_ticket_header_3">Invitation code</div>
									<div class="ac_create_ticket_header_4">Price (leaflet)</div>
									<div class="ac_create_ticket_header_5">Quantity</div>
								</div>
								<div
									class="ac_create_ticket_add"
									v-for="(item, index) in ticketArray"
									:key="index"
								>
									<div class="ac_create_ticket_add_name">
										<el-input
											placeholder="Please enter the activity name"
											v-model="item.name"
											:disabled="$route.query.type == 'detail'"
										>
										</el-input>
									</div>
									<div class="ac_create_ticket_add_type">
										<el-dropdown
											@command="ticketHandleCommand(index, $event)"
											class="ac_create_ticket_add_type_down"
											placement="bottom-start"
											size="medium"
											:disabled="$route.query.type == 'detail'"
										>
											<span class="el-dropdown-link">
												{{
													item.type == 999 || item.type == ""
														? "Official ticket"
														: item.type == 0
														? "Free ticket"
														: item.type == 1
														? "Discount ticket"
														: "Ordinary ticket"
												}}
												<img
													src="https://zy.metaera.media/assets/images/active/20.png"
												/>
											</span>

											<el-dropdown-menu
												class="ac_create_ticket_down_menu"
												slot="dropdown"
											>
												<el-dropdown-item
													command="0"
													class="ac_create_ticket_down_menu_item"
												>
													Free ticket
												</el-dropdown-item>
												<el-dropdown-item
													command="1"
													class="ac_create_ticket_down_menu_item"
												>
													Discount ticket
												</el-dropdown-item>
												<el-dropdown-item
													command="2"
													class="ac_create_ticket_down_menu_item"
												>
													Ordinary ticket
												</el-dropdown-item>
											</el-dropdown-menu>
										</el-dropdown>
									</div>
									<div class="ac_create_ticket_add_code">
										<el-input
											placeholder="Please enter the activity code"
											:disabled="
												item.type == 0 ||
												item.type == 2 ||
												$route.query.type == 'detail'
											"
											v-model="item.code"
										>
										</el-input>
									</div>
									<div class="ac_create_ticket_add_price">
										<el-input
											placeholder="Please enter the activity price"
											:disabled="
												item.type == 0 || $route.query.type == 'detail'
											"
											v-model="item.price"
										>
										</el-input>
									</div>
									<div class="ac_create_ticket_add_num">
										<el-input
											placeholder="Please enter the activity quantity"
											v-model="item.num"
											:disabled="$route.query.type == 'detail'"
										>
										</el-input>
									</div>
								</div>
							</div>
						</el-form-item>

						<el-form-item label="审核状态" class="margin_top_20" prop="status">
							<el-select v-model="formData.status" placeholder="">
								<el-option label="草稿" value="0"> </el-option>
								<el-option label="审核通过" value="1"> </el-option>
								<el-option label="审核不通过" value="2"> </el-option>
								<el-option label="审核中" value="3"> </el-option>
							</el-select>
						</el-form-item>

						<el-form-item label="审核不通过原因" prop="reason">
							<el-input v-model="formData.reason" placeholder=""></el-input>
						</el-form-item>

						<el-form-item
							label="首页Banner位"
							class="margin_top_20"
							prop="is_banner"
						>
							<el-select v-model="formData.is_banner" placeholder="">
								<el-option label="不显示" value="0"> </el-option>
								<el-option label="显示" value="1"> </el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="是否置顶">
							<el-radio v-model="formData.is_top" label="1">是</el-radio>
							<el-radio v-model="formData.is_top" label="0">否</el-radio>
						</el-form-item>

						<el-form-item label="MetaEra专属邀请码" prop="specialCode">
							<el-input
								v-model="formData.specialCode"
								placeholder="请输入需要显示的MetaEra专属邀请码"
							>
							</el-input>
						</el-form-item>
						<el-form-item>
							<div class="flex justify_content_c align_items_c">
								<el-button
									type="primary"
									class="custom_btn_width_100"
									@click="submit('formData')"
									>保存</el-button
								>
								<el-button
									class="custom_btn_width_100"
									@click="$router.push({ path: '/message/events' })"
									>返回</el-button
								>
							</div>
						</el-form-item>
					</el-form>
				</div>
			</div>
		</el-card>
	</div>
</template>

<script>
import VDistpicker from "v-distpicker";
import { validateNull } from "@/utils/validate";
import { createactivity, detailsactivity, updateactivity } from "@/api/article";
import addPeople from "./ac_people.vue";
export default {
	components: {
		VDistpicker,
		addPeople,
	},
	name: "",
	data() {
		return {
			rule: {
				title: [{ validator: validateNull, trigger: "blur", required: true }],

				pic: [{ validator: validateNull, trigger: "change", required: true }],
				start_time: [
					{ validator: validateNull, trigger: "change", required: true },
				],
				end_time: [
					{ validator: validateNull, trigger: "change", required: true },
				],
				organizer: [
					{ validator: validateNull, trigger: "change", required: true },
				],
				num: [{ validator: validateNull, trigger: "change", required: true }],
				is_free: [
					{ validator: validateNull, trigger: "change", required: true },
				],

				content: [{ validator: validateNull, trigger: "blur", required: true }],
				price: [{ validator: validateNull, trigger: "blur", required: true }],
				is_show: [
					{ validator: validateNull, trigger: "change", required: true },
				],
				type: [{ validator: validateNull, trigger: "change", required: true }],
				language: [
					{ validator: validateNull, trigger: "change", required: true },
				],
			},
			value: "",
			formData: {
				title: "",
				pic: "",
				start_time: "",
				end_time: "",
				organizer: "",
				num: "",
				is_free: "",
				content: "",
				is_show: "",
				type: "",
				language: "",
				address: "",
				province: "",
				city: "",
				area: "",
				price: "",
				id: "",
				is_banner: "0",
				is_top: "0",
				specialCode: "",
				status: "3",
				reason: "",
			},
			hostsArray: [],
			coHostsArray: [],
			ticketArray: [],
			placeholders: {
				province: "请选择省份",
				city: "请选择市",
				area: "请选择区或县",
			},
			//裁切图片参数
			cropperModel: false,
			cropperName: "",
		};
	},
	created() {
		if (!this.$route.query.id) {
			this.clearFormAdd();
		} else {
			this.detailsactivity();
		}
	},
	methods: {
		addHost() {},
		//封面设置
		uploadPicture(name) {
			if (this.$route.query.type == "detail") {
				// 详情不支持编辑图片
			} else {
				this.cropperName = name;
				this.cropperModel = true;
			}
		},
		//图片上传成功后
		handleUploadSuccess(data) {
			console.log(data.url);
			this.cropperModel = false;
			this.formData.pic = data.url;
		},
		detailsactivity() {
			const that = this;
			detailsactivity({ id: that.$route.query.id })
				.then((res) => {
					if (res.code == 200) {
						that.formData = res.data;
						that.formData.type = that.formData.type.toString();
						that.formData.status = that.formData.status.toString();
						that.formData.is_banner = that.formData.is_banner.toString();
						that.formData.language = that.formData.language.toString();
						that.formData.is_free = that.formData.is_free.toString();
						that.formData.is_show = that.formData.is_show.toString();
						that.formData.is_top = that.formData.is_top.toString();
						this.ticketArray = JSON.parse(res.data.ticket);
						this.hostsArray = JSON.parse(res.data.organizer);
						this.coHostsArray = JSON.parse(res.data.host);
					} else {
						that.$message.error(res.message);
					}
				})
				.catch((error) => {
					that.$message.error(error.message);
				});
		},
		onChangeProv(e) {
			console.log("省", e);
			this.formData.province = e.value;
		},
		onChangeCity(e) {
			console.log("市", e);
			this.formData.city = e.value;
		},
		onChangeArea(e) {
			console.log("区县", e);
			this.formData.area = e.value;
		},
		submit(formName) {
			if (
				this.formData.status == 2 &&
				(this.formData.reason == undefined || this.formData.reason.length == 0)
			) {
				this.$message.error("请填写审核不通过原因");
				return;
			}
			let obj = {
				id: this.formData.id,
				status: this.formData.status,
				is_banner: this.formData.is_banner,
				is_top: this.formData.is_top,
				reason: this.formData.reason,
				specialCode: this.formData.specialCode,
			};
			updateactivity(obj)
				.then((res) => {
					if (res.code == 200) {
						this.$router.push({ path: "/message/events" });
					} else {
					}
				})
				.catch((error) => {});
		},
		// detailsnotice() {
		//     const that = this;
		//     detailsnotice({ d: that.$route.query.id }).then((res) => {
		//         if (res.code == 200) {
		//             that.formData = res.data
		//             that.formData.status = that.formData.status.toString();
		//             that.formData.language = that.formData.language.toString()
		//         } else {
		//             that.$message.error(res.message);
		//         }
		//     }).catch((error) => {
		//         that.$message.error(error.message);
		//     });
		// },
		clearFormAdd() {
			for (let key in this.formData) {
				this.formData[key] = "";
			}
		},
	},
};
</script>

<style scoped>
.main {
	width: calc(100% - 40px);
}

.add_main {
	width: 70%;
	height: 81vh;
	overflow-y: scroll;
	margin: 0 auto;
}
</style>
<style>
.distpicker-address-wrapper select {
	width: 255px !important;
	padding: 0px 10px !important;
	height: 35px !important;
	font-size: 18px !important;
	line-height: 35px !important;
}
</style>
<!-- ac_create_ticket -->
<style scoped>
.ac_create_ticket {
	margin-top: 10px;
}
.ac_create_ticket_header {
	height: 60px;
	border-radius: 10px;
	background: rgba(240, 240, 240, 0.5);
	display: flex;
	align-items: center;
}

.ac_create_ticket_header_1,
.ac_create_ticket_header_2,
.ac_create_ticket_header_3,
.ac_create_ticket_header_4,
.ac_create_ticket_header_5 {
	width: 20%;
	text-align: center;
	align-content: center;
}

.ac_create_ticket_add {
	display: flex;
	margin-top: 20px;
	align-items: center;
}

.ac_create_ticket_add span {
	font-family: PingFang SC;
	font-size: 16px;
	font-weight: normal;
	line-height: 22px;
	display: flex;
	align-items: center;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 333 */
	color: #333333;
}

.ac_create_ticket_add_name {
	text-align: center;
	align-content: center;
	width: 16%;
	margin-left: 2%;
	margin-right: 2%;
}
.ac_create_ticket_add_type {
	text-align: center;
	align-content: center;
	width: 15%;
	padding-left: 1%;
	padding-right: 1%;
	margin-left: 2%;
	margin-right: 2%;
	height: 50px;
	border-radius: 10px;
	opacity: 1;
	border: 1px solid #333333;
}

.ac_create_ticket_add_type_down {
	width: 100%;
	height: 50px;
	border-radius: 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.ac_create_ticket_add_type_down span {
	display: flex;
	justify-content: space-between;
	width: 100%;
}

.ac_create_ticket_down_menu {
	width: 160px;
}

.ac_create_ticket_down_menu_item {
	height: 40px;
	/* text-align: center; */
	align-content: center;
	align-items: center;
}

.ac_create_ticket_add_code {
	text-align: center;
	align-content: center;
	width: 16%;
	margin-left: 2%;
	margin-right: 2%;
}
.ac_create_ticket_add_price {
	text-align: center;
	align-content: center;
	width: 16%;
	margin-left: 2%;
	margin-right: 2%;
}
.ac_create_ticket_add_num {
	text-align: center;
	align-content: center;
	width: 16%;
	margin-left: 2%;
	margin-right: 2%;
}

.ac_create_ticket_add_num_del {
	width: 34px;
	height: 34px;
	opacity: 1;
}
.ac_create_ticket_add_num_del img {
	width: 100%;
	height: 100%;
}
</style>
